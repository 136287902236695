import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

// SENTRY
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';

// TYPESENSE
import InstantSearch from 'vue-instantsearch/vue3/es'; // Vue 3
import 'instantsearch.css/themes/satellite.css';
// import 'instantsearch.css/themes/reset.css';

import 'bootstrap/dist/css/bootstrap.min.css';

// font awesome
// import { library } from "@fortawesome/fontawesome-svg-core";
// import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
// library.add(faEye, faEyeSlash);
// "@fortawesome/fontawesome-svg-core": "^1.2.36",
// "@fortawesome/free-solid-svg-icons": "^5.15.4",
// "@fortawesome/vue-fontawesome": "^2.0.2",

// const app = createApp(App);

// // app.component("font-awesome-icon", FontAwesomeIcon);

// app
//   .use(store)
//   .use(router)
//   .mount("#app");

import { auth, onAuthStateChanged } from '@/services/firebase';

let app;
onAuthStateChanged(auth, async (user) => {
  // CORE LOGIC (order is important)
  // do not create app, if we dont know auth/unauth state
  // if authed, await the token as well, since it is needed in some views (like profile)
  // then make app. if this is a subsequent auth state change, ignore this step
  // treat certain routes with redirects, given auth state

  if (user) {
    await user
      .getIdToken(true)
      .then((idToken) => {
        // Send token to your backend via HTTPS
        store.commit('setToken', idToken);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  store.commit('setUser', user);

  if (!app) {
    app = createApp(App);

    Sentry.init({
      app,
      release: `${process.env.VUE_APP_NAME}@${process.env.VUE_APP_VERSION}@${process.env.VUE_APP_GIT_COMMIT}`,
      dsn: `${process.env.VUE_APP_SENTRY_DSN}`,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: ['*'],
        }),
      ],
      ignoreErrors: [
        'TypeError: Illegal invocation',
        'TagError: adsbygoogle.push() error: No slot size for availableWidth=0',
        'Non-Error promise rejection captured with value: undefined',
        "Cannot read properties of null (reading 'CodeMirror')",
        'timeout of 10000ms exceeded',
        'M',
        'Page requested below 0.',
      ],
      tracesSampleRate: 0.05,
      logErrors: true,
      trackComponents: true,
      hooks: ['activate', 'create', 'destroy', 'mount', 'unmount', 'update'],

      beforeSend(event, hint) {
        const error = hint.originalException;
        console.log('inside sentry before send', 'ev=', event, 'er=', error, 'ermsg=', error?.message);

        if (error && error?.message && error.message.match(/Loading chunk/i)) {
          alert(
            'Η έκδοση της εφαρμογής του ΤρελουΦοιτητη που χρησιμοποιείτε χρειάζεται ενημέρωση. Για να συνεχίσετε πραγματοποίηστε ΑΝΑΝΕΩΣΗ / REFRESH της σελίδας.'
          );
          window.location.reload();
          return null;
          //If you return null, the event will be discarded.
        } else if (error && error?.message && error.message.match(/Loading CSS chunk/i)) {
          alert(
            'Η έκδοση της εφαρμογής του ΤρελουΦοιτητη που χρησιμοποιείτε χρειάζεται ενημέρωση. Για να συνεχίσετε πραγματοποίηστε ΑΝΑΝΕΩΣΗ / REFRESH της σελίδας.'
          );
          window.location.reload();
          return null;
          //If you return null, the event will be discarded.
        }

        return event;
      },
    });

    app.use(InstantSearch).use(store).use(router).mount('#app');
  }

  console.log(
    `AUTH_STATE CHANGED: User=${store.state.user ? store.state.user.email : store.state.user}, Token=${
      store.state.token != null ? 'not null' : 'null'
    }, EmailVerified=${store.state.email_verified}`
  );
});
